import type { Matcher } from "@testing-library/dom";
import { partialBind } from "remeda";
import invariant from "tiny-invariant";
import type { BrowserTestContext } from "../../../../dev/BrowserTestContext.ts";

export async function fill({ canvas, userEvent }: BrowserTestContext, label: Matcher, value: string) {
    const input = await canvas.findByLabelText(label);
    invariant(input instanceof HTMLInputElement, `Expected ${label} to be an input element`);
    if (input.value !== "") {
        await userEvent.clear(input);
    }
    await userEvent.type(input, value);
}

const cardInfo = {
    success: {
        name: "John Doe",
        number: "4242424242425678",
        expiration: "11/27",
        security_code: "123",
    },
    invalid_number: {
        name: "John Doe",
        number: "12345",
        expiration: "11/27",
        security_code: "123",
    },
};

type CardType = keyof typeof cardInfo;

export function accountCreateInteract(ctx: BrowserTestContext, cardType: CardType) {
    const { userEvent, canvas, step } = ctx;

    return {
        async run() {
            await this.fillForm();
            await this.clickCreate();
        },

        async runAndCheck() {
            await this.run();
            await this.checkResult();
        },

        async fillForm() {
            const fill_ = partialBind(fill, ctx);

            await step("Fill the billing account fields", async () => {
                await fill_("Billing account name", "My billing account");
                await fill_("Invoice email", "mock@warren.io");
                await fill_("Contact person's phone", "1234567890");
            });

            await step("Select the Omise payment method", async () => {
                const omiseMethod = await canvas.findByText(/Credit card( \(Omise\)|$)/);
                await userEvent.click(omiseMethod);
            });

            await fillPaymentInfo(ctx, cardType);
        },

        async clickCreate() {
            const createButton = await canvas.findByRole("button", { name: "Create" });
            await userEvent.click(createButton);
        },

        async checkResult() {
            await step("Wait for the account to be created", async () => {
                if (cardType === "success") {
                    // NB: It takes a while for the popup / verification to progress, so use a longer timeout
                    await canvas.findByText("Payment Methods", undefined, { timeout: 9000 });
                    await canvas.findByText("Visa*5678", { exact: false });
                } else {
                    // Find the error toast
                    await canvas.findByText("Adding payment method failed:", { exact: false });
                }
            });
        },
    };
}

export async function fillPaymentInfo(c: BrowserTestContext, cardType: CardType) {
    await c.step(`Fill in payment info (card type ${JSON.stringify(cardType)})`, async () => {
        const info = cardInfo[cardType];

        await fill(c, "Name (Min 4 chars)", info.name);
        await fill(c, "Card Number", info.number);
        await fill(c, "Valid thru", info.expiration);
        await fill(c, "CVC", info.security_code);
    });
}
